
import { defineComponent, reactive, toRefs, ref, watch, onDeactivated, onMounted, computed } from "vue";
import { TYPE_STATUS, TASK_TYPE, PRIORITY, setObjStringify, TASK_TYPE_LIST, removeIdFromRouter, addDetailIdToRouter } from "@/utils";
import { Pagation } from "@/composables/usePagation";
import useRequest from "@/composables/useRequest";
import useRenderTable from "@/composables/useRenderTable";
import useFetchSearch from "./composables/useFetchSearch";
import useMixin from "../useMixin";
import FullScreen from "@/components/fullscreen/index.vue";
import useWatchChangeProduct from "@/composables/useWatchChangeProduct";
import ImportExcelComp from "@/components/importExcel/index.vue";

import {
  updateBasicTask,
  detailTask,
  detailTaskLog,
  relativeTask,
  createTaskList,
  deleteTask,
  relativeTaskIteration,
  creatorTask
} from "@/api/request-modules/iteration";
import { allMember } from "@/api/request-modules/product";
import { ElLoading } from "element-plus";
import dialogDemand from "@/businessComponents/demandDialog/index.vue";
import selectOption from "@/businessComponents/selectOption/index.vue";

import { ElMessageBox, ElMessage } from "element-plus";
import { getIterateList } from "@/api/request-modules/board";
import { ProjectItem } from "@/composables/useCommon";
import { USER } from "@/store/state";
import InputOption from "@/businessComponents/inputOption/index.vue";
import { ResponseParams } from "@/types/response";
import useMessageTip from "@/composables/useMessageTip";
import { useRouter, useRoute } from "vue-router";
import { getSession, setSession, clearSession } from "@/utils";
import { useStore } from "@/store/index";
import { ArrowUp } from "@element-plus/icons";
import { SearchArray } from "@/types/interface";
import FormTask from "./components/formTask.vue";
import Drawer from "@/components/drawer/index.vue";
import { MutationType } from "@/store/mutation-types";
import type { OrderSort, BasicsOption } from "@/composables/useCommon";
import api from "@/api/dict";
import useExploitation from "@/composables/useExploitation";
import { systemBehaviorStore } from "@/utils/contantOptions";

export default defineComponent({
  name: "exploitation",
  components: {
    ArrowUp,
    InputOption,
    FormTask,
    FullScreen,
    Drawer,
    dialogDemand,
    selectOption,
    ImportExcelComp
  },
  setup() {
    const router = useRouter();
    // 任务在项目下不可新增,任务在迭代模块下可以新增任务
    const showAddTaskBtn = router.currentRoute.value.meta.onIteration;
    const loading = ref(false);
    const showImportExcel = ref(false);
    const { useTaskImportExcel } = useExploitation();
    const productId = computed(() => store.getters.productId);

    const route = useRoute();
    const store = useStore();
    const basicBtn = ref();
    const cascaderPanel = ref();
    const curProductInfo = computed(() => store?.getters?.productInfo);
    const { tipMessage } = useMessageTip();
    const user = getSession(systemBehaviorStore.systemUserinfo, true) as USER;
    const headerTitle = ref("新增任务");
    const dialogDemand = ref(false);
    const isFullScreen = ref(false);
    // 编辑状态弹框参数
    const dialogFormData = reactive<any>({
      status: null,
      ids: [],
      real_hours: ""
    });
    // 表格参数
    const tableData = reactive({
      list: [],
      total: 0,
      bugType: {
        hour_percent: 0,
        remain_hours: 0,
        total_hours: 0
      }
    });
    const currentActive = ref("-1");
    const page = ref(1);
    // 判断是否进行筛选
    const flag = ref<boolean>(false);
    const pageTableRef = ref<any>();
    const stopAutoGetData = ref<boolean>(false);
    // 获取当前迭代
    const iterateId = computed(() => store.getters.iterateId);
    const { searchParams, handleGetIterationList, newDemandLists, newBasicDemandLists } = useMixin();

    const hasData = ref(true);
    const onIntertion = () => {
      if (router.currentRoute.value.name === "exploitation" && !iterateId.value) hasData.value = false;
      else hasData.value = true;
    };
    onIntertion();

    // 列表默认按状态排序，未开始>进行中>已完成，所以sort_name默认传status，sort_by：1
    const formParams: Record<string, any> = ref({
      staff_no: [] as Array<string>,
      staff_name: [] as Array<string>,
      type: [],
      iteration_id: null,
      product_id: curProductInfo.value?.id,
      demand_id: [],
      name: "",
      status: [],
      expected_start_time: [],
      time: "",
      sort_name: "",
      sort_by: 0
    });
    const defaultSearchParams = {
      staff_no: [] as Array<string>,
      staff_name: [] as Array<string>,
      type: [],
      iteration_id: null,
      product_id: curProductInfo.value?.id,
      demand_id: [],
      name: "",
      status: [],
      expected_start_time: [],
      time: "",
      sort_name: "",
      sort_by: 0
    };

    const defaultTaskForm = {
      attachment: [],
      begin_time: undefined,
      status: 0,
      content: "",
      end_time: undefined,
      hours: 0,
      real_hours: 0,
      iteration_id: null,
      iteration_name: "",
      demand_id: null,
      level: "3",
      type: 2,
      name: "",
      product_id: curProductInfo.value?.id,
      product_name: curProductInfo.value?.name,
      staff_name: user?.name,
      staff_no: user?.staff_no,
      remark: "",
      start_time: undefined
    };

    // 抽屉组件form表单
    const taskForm = ref<Record<string, any>>({});

    const richStatus = ref("view");
    const rich2Status = ref("view");
    // 抽屉组件默认隐藏
    const drawer = ref(false);

    // 关联需求弹框
    const dialogRelative = computed(() => store.state.iterateContentVisible);
    // 关联任务弹窗默认选中
    const defaultCheck = ref([4, 5]);

    // 关联任务弹窗列表数据
    const relativeTaskList = ref([]);

    // 组件被销毁是  组件是包在keep-alice中
    onDeactivated(() => {
      drawer.value = false;
    });

    // 判断预计起止
    const timeCheck = (params: Record<string, any>, type: number) => {
      const colorDefault = "#606266";
      const colorOne = "#ff0000";
      if (!type) {
        if (params.delay_state === 1 || params.delay_state === 3) return colorOne;
        else return colorDefault;
      } else {
        if (params.delay_state === 2 || params.delay_state === 3) return colorOne;
        else return colorDefault;
      }
    };

    // 表格多选
    const selectMore: any = ref([]);

    onMounted(() => {
      const { status, where, detailId } = route.query as Record<string, string>;
      if (where === "home") {
        // 重写将默认值赋给searchParams
        formParams.value = JSON.parse(JSON.stringify(defaultSearchParams));
        formParams.value.product_id = curProductInfo.value?.id;
        formParams.value.iteration_id = 0;
        currentActive.value = "-10";
        formParams.value.status = status?.split(",").map((i) => parseInt(i));
        searchArray.searchForm[3].val = formParams.value.status;

        getData(pageTableRef.value?.getCurrentPage());

        setTimeout(() => {
          // 去掉路由的参数
          router.replace({
            path: route.path,
            query: { ...route.query, status: undefined, where: undefined }
          });
        }, 700);
      } else if (detailId) {
        if (router.currentRoute.value.name === "exploitation" || router.currentRoute.value.name === "projectExploitation") {
          handleDetail({ id: detailId });
        }
      }

      getUserList();

      // 获取迭代列表
      getIterateData();
      // 获取项目列表

      getProjectList();
      // 获取创建人列表
      getCreatorTask();
    });

    const tableHeight = () => {
      setTimeout(() => {
        const contentHeight = document.getElementsByClassName("content")[0] as HTMLDivElement;
        const headerHeight = document.getElementsByClassName("search-header")[0] as HTMLDivElement;
        if (showAddTaskBtn) {
          if (pageTableRef.value) pageTableRef.value.height = contentHeight.offsetHeight - headerHeight.offsetHeight - 110;
        } else {
          if (pageTableRef.value) pageTableRef.value.height = contentHeight.offsetHeight - headerHeight.offsetHeight - 130;
        }
      }, 100);
    };
    // 条件搜索
    // let timer: ReturnType<typeof setTimeout>;
    // 分页以及获取数据  type代表使用筛选框进行迭代筛选，不可过滤掉
    const getData = async (pagationParams?: Pagation, flag?: boolean, params?: any, type?: number) => {
      if (params && !params?.product_id) {
        hasData.value = false;
        return;
      }
      stopAutoGetData.value = flag == undefined ? false : true;
      page.value = (pagationParams && pagationParams.pageIndex) || 1;
      let paramsObj: any = {};
      // 项目下不需要传迭代(但是进行迭代搜索则不要过滤掉)
      if (showAddTaskBtn) {
        paramsObj = JSON.parse(JSON.stringify(params || Object.assign(formParams.value, { iteration_id: iterateId.value })));
        if (!type) {
          if (searchArray?.searchForm && searchArray.searchForm[7]?.val) {
            paramsObj.iteration_id = searchArray.searchForm[7].val ? searchArray.searchForm[7].val : iterateId.value;
            if (currentActive.value === "-1") delete paramsObj.iteration_id;
          } else {
            delete paramsObj.iteration_id;
          }
        } else {
          paramsObj.iteration_id = searchArray?.searchForm[7].val ? searchArray.searchForm[7].val : null;
        }
      } else {
        paramsObj = JSON.parse(JSON.stringify(params || Object.assign(formParams.value, { iteration_id: iterateId.value })));
        if (type) {
          paramsObj.iteration_id = searchArray.searchForm[7].val?.length ? searchArray.searchForm[7].val : iterateId.value;
        }

        // 在迭代下的任务
      }

      // 在项目下如果没有迭代则不进行请求
      if (!showAddTaskBtn && !iterateId.value) {
        tableData.total = 0;
        tableData.list = [];
        tableData.bugType.hour_percent = 0;
        tableData.bugType.remain_hours = 0;
        tableData.bugType.total_hours = 0;
        return;
      }
      const { response } = useRequest(useFetchSearch, paramsObj);
      const { pagation } = useRenderTable(response);
      let {
        data: { list, count, hour_percent, remain_hours, total_hours }
      } = await pagation(pagationParams);
      tableData.total = count;
      tableData.list = list;
      tableData.bugType.hour_percent = hour_percent;
      tableData.bugType.remain_hours = remain_hours;
      tableData.bugType.total_hours = total_hours;
      tableHeight();
    };

    const handleConditionSearch = async (isHiddenSelect?: boolean, isSearch?: boolean) => {
      if (isHiddenSelect === true) {
        return;
      }

      if (flag.value) {
        pageTableRef.value.setCurrentPage();
        page.value = 1;
        await getData({ pageIndex: 1, pageSize: 20 }, undefined, Object.assign(formParams.value, { iteration_id: iterateId.value }));
        flag.value = false;
      } else {
        await getData(pageTableRef.value?.getCurrentPage(), undefined, Object.assign(formParams.value, { iteration_id: iterateId.value }));
      }
      stopAutoGetData.value = false;
      if (!isSearch) await handleGetIterationList();
      getUserList();
    };

    // 查看详情
    const handleDetail = (row: any) => {
      headerTitle.value = "查看详情";
      richStatus.value = "view";
      rich2Status.value = "view";
      drawer.value = true;
      loading.value = true;
      getdemandDetailFn(row.id);
      addDetailIdToRouter(row.id);
    };
    // 获取任务详情
    const getdemandDetailFn = async (id: number) => {
      await getDetailTaskLog(id);
      await detailTask<ResponseParams.ResponseDataSuccess>(id)
        .then((res) => {
          taskForm.value = res.data;
          taskForm.value.begin_time = [res.data.begin_time, res.data.end_time];
          taskForm.value.level = taskForm.value.level ? taskForm.value.level + "" : undefined;
          taskForm.value.iteration_id = taskForm.value.iteration_id ? taskForm.value.iteration_id : null;
          taskForm.value.demand_id = taskForm.value.demand_id ? taskForm.value.demand_id : null;
        })
        .finally(() => {
          loading.value = false;
        });
    };
    // 获取任务详情

    // 获取动态日志
    const logData: any = ref([]);
    const getDetailTaskLog = (id: number) => {
      detailTaskLog<ResponseParams.ResponseDataSuccess>(id).then((res: any) => {
        if (res.data && res.data.length) {
          logData.value = res.data;
        } else {
          logData.value = [];
        }
      });
    };
    // 获取动态日志

    // 获取用户下拉列表
    const userList: any = ref([]);
    const userListAll: any = ref([]);
    const basicUserList: any = ref([]);
    // 这里的is_create 参数是用来区分在职也离职人员
    const getUserList = (is_create?: boolean) => {
      if (!curProductInfo.value?.id) return;
      allMember<ResponseParams.ResponseDataSuccess>({ product_id: curProductInfo.value?.id, is_create: !is_create }).then((res: any) => {
        if (res.data && res.data.length) {
          if (!is_create) {
            userList.value = res.data;
            basicUserList.value = res.data[1].options.map((o: Record<string, any>) => {
              return { value: o.staff_no, label: o.staff_name };
            });
          } else {
            userListAll.value = res.data;
          }
        } else {
          userList.value = [];
          userListAll.value = [];
          basicUserList.value = [];
        }
      });
    };
    // 获取用户下拉列表

    const onChangeTask = (id: number) => {
      getDetailTaskLog(id);
    };
    const taskFormParams = ref();
    // 条件搜索
    // 监听项目更改
    useWatchChangeProduct(handleConditionSearch, async () => {
      if (formParams.value.product_id != curProductInfo.value?.id) formParams.value.iteration_id = null;
      formParams.value.product_id = curProductInfo.value?.id;
      formParams.value.iteration_id = iterateId.value;
      handleConditionSearch();
      getUserList();
    });

    // 新增任务
    const addTaskDate = () => {
      headerTitle.value = "新增任务";
      richStatus.value = "add";
      rich2Status.value = "add";
      defaultTaskForm.product_id = curProductInfo.value?.id;
      defaultTaskForm.product_name = curProductInfo.value?.name;

      taskForm.value = JSON.parse(JSON.stringify(defaultTaskForm));
      taskForm.value.demand_name = "";
      // 如果是在迭代下的任务，则需要把项目和迭代置灰，不可选择
      if (!showAddTaskBtn) {
        taskForm.value.iteration_id = iterateId.value;
      }
      setTimeout(() => {
        drawer.value = true;
      }, 500);
      logData.value = [];
    };
    // 新增任务

    // 获取关联迭代下拉数据
    interface IterateBasicData {
      value: string;
      label: string;
    }
    let timerInter: ReturnType<typeof setTimeout>;
    const iterateData = ref([]);
    const iterateBasicData = ref<IterateBasicData[]>([]);
    const getIterateData = () => {
      if (!curProductInfo.value?.id) return;
      const obj = {
        product_id: curProductInfo.value?.id,
        iteration_id: iterateId.value
      };

      clearTimeout(timerInter);

      timerInter = setTimeout(() => {
        // 项目下不需要传迭代
        if (showAddTaskBtn) delete obj.iteration_id;
        getIterateList<ResponseParams.ResponseDataSuccess>(obj).then((res) => {
          if (res.code === 200) {
            iterateData.value = res.data;
            iterateBasicData.value = res.data.map((o: Record<string, any>) => {
              return { value: o.id, label: o.name };
            });
          } else {
            iterateData.value = [];
            iterateBasicData.value = [];
          }
        });
      }, 1000);
    };
    // 获取关联迭代下拉数据

    // 获取项目列表
    const productLists = ref<ProjectItem[]>([]);

    const getProjectList = async () => {
      const data1 = computed(() => store.getters.productList);
      const data = JSON.parse(JSON.stringify(data1.value));
      if (!data) return;
      productLists.value = data.map((o: ProjectItem) => {
        return { ...o, value: o.id, label: o.name };
      });
    };
    // 获取项目列表

    interface StaffData {
      ids?: number[];
      field: string;
      staff_name: string;
      staff_no: string;
    }
    // 指派人回调
    const updateVal = (val: string | StaffData) => {
      if (val === "no") {
        // 弹框取消操作
        getData(pageTableRef.value?.getCurrentPage());
        return;
      }

      let obj = val as StaffData;
      obj.ids = getSelectId();
      updateData(obj);

      dialogDemand.value = false;
    };

    // 获取创建人下拉数据
    const creatorTaskData = ref([]);
    const creatorBasicTask = ref<IterateBasicData[]>([]);
    const getCreatorTask = () => {
      if (!curProductInfo.value?.id) return;
      const obj = {
        product_id: curProductInfo.value?.id,
        iteration_id: iterateId.value
      };
      // 项目下不需要传迭代
      if (showAddTaskBtn) delete obj.iteration_id;
      creatorTask<ResponseParams.ResponseDataSuccess>(obj).then((res: any) => {
        if (res.code === 200) {
          creatorTaskData.value = res.data;
          creatorBasicTask.value = res.data[1]?.options.map((o: Record<string, any>) => {
            return { value: o.staff_no, label: o.staff_name };
          });
        } else {
          creatorTaskData.value = [];
          creatorBasicTask.value = [];
        }
      });
    };
    // 获取创建人下拉数据

    // 监听迭代变化
    watch(
      () => iterateId.value,
      () => {
        handleConditionSearch();
        getIterateData();
        getProjectList();
        getCreatorTask();
        onIntertion();
      }
    );
    // 监听迭代变化

    // 监听抽屉关闭请求数据
    watch(
      () => drawer.value,
      (newVal) => {
        if (!newVal) {
          removeIdFromRouter();
          if (searchArray.searchForm[7].val || showAddTaskBtn) {
            getData(pageTableRef.value?.getCurrentPage(), undefined, searchParams.value, 1);
          } else {
            getData(pageTableRef.value?.getCurrentPage(), undefined, searchParams.value);
          }
          // 这里延迟请求是因为立即执行页面会有数据切换，交互不好
          setTimeout(() => {
            getUserList();
          }, 500);
        }
      }
    );
    // 监听抽屉关闭请求数据

    // 未完成、已完成、P0快捷事件
    const handleTypeList = (isType: string, params: number[]) => {
      formParams.value.status = params;
      currentActive.value = "0,1";
      getData(pageTableRef.value?.getCurrentPage(), undefined, searchParams.value);
      // handleConditionSearch();
    };

    const getStatus = (status: number) => {
      return TASK_TYPE.find((t) => t.value === status)?.label;
    };

    // 关联任务
    const handleRelativeTask = () => {
      const obj = {
        product_id: curProductInfo.value?.id,
        iteration_id: searchParams.demand,
        filter_type: 1
      };
      relativeTask<ResponseParams.ResponseDataSuccess>(obj).then((res: any) => {
        if (res.code === 200) {
          relativeTaskList.value = res.data.map((o: { id: number; name: string }) => {
            return { value: o.id, label: o.name };
          });
          setTimeout(() => {
            // dialogRelative.value = true;
            store.commit(MutationType.iterateContentVisible, true);
          }, 200);
        }
      });
    };

    // 关联任务后回调
    const getRelativeDate = (val: number[]) => {
      const obj = {
        iteration_id: iterateId.value,
        task_ids: val
      };
      if (!val.length) {
        ElMessage({
          showClose: true,
          message: "请选中要关联的任务",
          type: "warning"
        });
        return;
      }
      relativeTaskIteration<ResponseParams.ResponseDataSuccess>(obj).then((res) => {
        if (res.code === 200) {
          store.commit(MutationType.iterateContentVisible, false);

          getData(pageTableRef.value?.getCurrentPage());
        }
      });
    };
    // 关联任务后回调

    // 批量添加
    const handleAddDate = () => {
      const a = router.resolve({
        name: "exploitationAdd",
        query: {
          type: showAddTaskBtn ? 0 : 1
        }
      });
      window.open(a.href, "_blank");
    };
    // 删除 val存在代表单个删除 否则多个删除
    const handleDelete = (val?: number) => {
      ElMessageBox.confirm("此操作将永久删除，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "el-button--danger-box",
        type: "warning"
      }).then(() => {
        if (val) removeTask({ ids: [val] });
        else removeTask({ ids: getSelectId() });
      });
    };

    const removeTask = (params: { ids: number[] }) => {
      deleteTask<ResponseParams.ResponseDataSuccess>(params).then((res) => {
        if (res.code === 200) {
          getData(pageTableRef.value?.getCurrentPage(), undefined, searchParams.value);
        }
      });
    };
    // 删除 val存在代表单个删除 否则多个删除

    // 导出
    const exportTaskDate = async () => {
      let exportForm = JSON.parse(JSON.stringify(formParams.value));
      // 项目下不需要传迭代
      if (showAddTaskBtn) delete exportForm.iteration_id;

      if (exportForm.time && exportForm.time.length) {
        exportForm.expected_start_time = exportForm.time;
        delete exportForm.time;
      }
      delete exportForm.times;
      delete exportForm.time;
      const keyArr = ["status", "type", "level", "staff_no", "create_by"];
      for (let i = 0; i < keyArr.length; i++) {
        if (exportForm[keyArr[i]] && !exportForm[keyArr[i]].length) delete exportForm[keyArr[i]];
        if (!exportForm[keyArr[i]]) delete exportForm[keyArr[i]];
      }
      if (!tableData.total) return tipMessage(400, "暂无数据，无法导出");
      setObjStringify(exportForm, "/api/tomato/task/export");
    };

    const task_select = JSON.parse(JSON.stringify(TASK_TYPE));

    let TYPE_STATUS_COPY = [
      {
        value: 2,
        label: "已完成",
        color: "#666666"
      }
    ];
    // 更新状态
    const handleEditStatus = (row: Record<string, any>) => {
      const { id, real_hours, hours } = row;
      dialogFormData.status = 2;
      dialogFormData.ids = [id];
      dialogFormData.field = "status";
      dialogFormData.real_hours = real_hours ? real_hours * 1 : hours * 1;
      isOpenEditProgress.value = true;
    };

    // 编辑状态-确定 单个
    const handleUpdateStatus = () => {
      dialogFormData.real_hours = dialogFormData.real_hours * 1;
      const r = /^\+?[0-9][0-9]*$/;
      if (!r.test(dialogFormData.real_hours)) return tipMessage(400, "请输入大于0的正整数");

      if (dialogFormData.real_hours > 999 || dialogFormData.real_hours === 0) return tipMessage(400, "请输入0到999的正整数");

      updateData(dialogFormData);
    };
    // 编辑状态 单个

    // 取消
    const handleCancle = () => {
      isOpenEditProgress.value = false;
      getData(pageTableRef.value?.getCurrentPage(), undefined, formParams.value);
    };
    // 取消

    // 更改状态、预计工时、指派人
    const onChangeType = (val: Record<string, any>, item: Record<string, any>, type?: string) => {
      console.log(val);
      const obj: Record<string, any> = {
        ids: [item.id]
      };
      // 修改预计工时
      if (type === "hours") {
        obj.hours = Number(val.label);
        obj.field = "hours";
      }
      updateData(obj);
    };
    // 更改状态、等级、指派人
    const onChangeSelect = (val: string | number, item: Record<string, any>, key?: string) => {
      const obj: Record<string, any> = {
        ids: [item.id],
        field: key
      };
      if (key === "staff_no") {
        const person = basicUserList.value.filter((item: Staff) => item.value === val);
        obj.staff_no = person[0].value;
        obj.staff_name = person[0].label;
      }
      if (key === "level") obj.level = val;

      if (key === "status") {
        obj.status = val;
        if (val === 2) {
          // 状态已完成是需要填工时
          item.status === 2;
          handleEditStatus(item);
          return;
        }
      }

      console.log(obj);
      updateData(obj);
    };
    // 结果 -- 批量修改状态
    const handleCommand = (command: string) => {
      handleChangeStatus(Number(command));
      dialogFormData.status = Number(command);
      dialogFormData.ids = [];
      if (selectMore.value && selectMore.value.length) {
        selectMore.value.forEach((item: Record<string, any>) => {
          dialogFormData.ids.push(item.id);
        });
      } else {
        return tipMessage(400, "请选择列表");
      }
      handleUpdateStatus();
    };
    let isOpenEditProgress = ref(false);
    // 批量编辑、复制  type:1 编辑  type:2 复制
    const handleUpdateList = (type: number) => {
      if (selectMore.value && selectMore.value.length) {
        setSession("editTask", JSON.stringify(selectMore.value));
      } else {
        return tipMessage(400, "请选择要批量操作的列表");
      }
      const a = router.resolve({
        name: "exploitationAdd",
        query: Object.assign(
          {
            isEdit: type,
            type: showAddTaskBtn ? 0 : 1
          },
          { ...router.currentRoute.value.query }
        )
      });

      window.open(a.href, "_blank");
    };
    //#region  更改状态弹窗
    const handleChangeStatus = (val: number) => {
      dialogFormData.status = val;
    };

    const handleSelectionChange = (val: any) => {
      selectMore.value = val;
    };

    //#endregion
    const getTextColor = (status: number) => {
      const list = TASK_TYPE.find((v: { value: number; label: string }) => v.value === status);
      return {
        color: list?.color
      };
    };

    // 自定义排序
    const sortChange = (e: OrderSort) => {
      const { prop, order } = e;
      if (!order) return;
      formParams.value.sort_name = prop;
      formParams.value.sort_by = order === "ascending" ? 1 : 2;
      if (searchArray.searchForm[7].val) {
        getData(pageTableRef.value?.getCurrentPage(), undefined, searchParams.value, 1);
      } else {
        getData(pageTableRef.value?.getCurrentPage(), undefined, searchParams.value);
      }
    };

    const getSelectId = () => {
      let ids: number[] = [];
      selectMore.value?.forEach((item: Record<string, any>) => {
        ids.push(item.id);
      });
      return ids;
    };
    // 基础向批量  编辑更新
    const updateData = (params: Record<string, any>) => {
      updateBasicTask<ResponseParams.ResponseDataSuccess>(params).then((res) => {
        if (res.code === 200) {
          getData(pageTableRef.value?.getCurrentPage(), undefined, searchParams.value);
          isOpenEditProgress.value = false;
          // 编辑标题是更新动态
          if (params.field === "name") {
            onChangeTask(params.ids[0]);
          }
          if (params.field === "staff_no") {
            // 这里延迟请求是因为立即执行页面会有数据切换，交互不好
            setTimeout(() => {
              getUserList();
            }, 500);
          }
          return tipMessage(200, "成功");
        }
      });
    };
    const onChangeVal = (params: Record<string, any>, key: string) => {
      if (key === "complete_percent") params.field = "complete_percent";

      if (key === "hours") params.field = "hours";

      updateData(params);
    };
    interface Staff {
      value: string;
      staff_no: string;
    }
    // 基础向批量操作
    const handleBasicChange = (val: any) => {
      // 调起弹框组件 指派给
      if (val[0] === "staff_no") {
        // 指派人
        dialogDemand.value = true;
      } else {
        const obj: any = {
          ids: getSelectId()
        };
        const keyArr = ["status", "level", "type", "iteration_id", "product_id", "demand_id"];
        const label = cascaderPanel.value.getCheckedNodes()[0].value;
        obj[val[0]] = label;
        if (keyArr.includes(val[0])) {
          obj[val[0]] = label * 1;
        }
        obj.field = val[0];
        // 如果是指派人，需要把指派人名字传过去，其它只传id即可
        if (val[0] === "staff_no") {
          const userMsg = basicUserList.value.filter((item: Staff) => item.value === label);
          obj.staff_name = userMsg[0].label;
        }
        updateData(obj);
      }

      // 隐藏基础项
      basicBtn?.value.handleClose();
    };
    // 快速查询
    const quickSeacrh = async (val: Record<string, any>) => {
      const productInfo = getSession("productInfo", true) as Record<string, any>;
      // 重写将默认值赋给searchParams
      formParams.value = JSON.parse(JSON.stringify(defaultSearchParams));
      formParams.value.product_id = productInfo.id;
      formParams.value.iteration_id = searchParams.demand;
      // val.key不存在代表获取所有数据
      if (val.key) {
        const obj: Record<string, any> = {
          create_by: [user?.staff_no],
          staff_no: [user?.staff_no]
        };
        let params: Record<string, any> = {};
        params.product_id = formParams.value.product_id;

        params[val.key] = obj[val.key];
        if (val.key === "status") {
          params[val.key] = val.id.split(",");
        }

        formParams.value[val.key] = params[val.key];
      }

      searchData(1);
    };
    // 点击搜索弹框选中搜索条件查询
    const changeSearch = async (val: Record<string, any>) => {
      delete formParams.value.expected_start_time;
      delete formParams.value.expected_end_time;
      delete formParams.value.actual_start_time;
      delete formParams.value.actual_end_time;
      currentActive.value = "-10";
      let params: Record<string, any> = JSON.parse(JSON.stringify(val));
      params.product_id = curProductInfo.value.id;
      // params.iteration_id = searchParams.demand;
      // 遍历将值更新到formParams
      for (var obj in val) {
        formParams.value[obj] = val[obj];
      }
      searchData();
      // 这里延迟请求是因为立即执行页面会有数据切换，交互不好
      setTimeout(() => {
        getUserList();
      }, 500);
    };

    // quick存在代表是使用快速搜索
    const searchData = async (quick?: number) => {
      flag.value = true;
      page.value = 1;
      pageTableRef.value.setCurrentPage();

      if (searchArray.searchForm[7].val && !quick) {
        getData(pageTableRef.value?.getCurrentPage(), undefined, searchParams.value, 1);
      } else {
        getData(pageTableRef.value?.getCurrentPage(), undefined, searchParams.value);
      }
    };
    interface WangText {
      ids: number[];
      content?: string;
      remark?: string;
      name?: string;
      field?: string;
    }
    // 编辑或者新增任务  type: add代表创建任务，edit代表编辑描述或者备注
    const editOrAddTask = async (params: any, type: string) => {
      if (type === "add") {
        const loading = ElLoading.service({
          lock: true,
          text: "Loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        createTaskList<ResponseParams.ResponseDataSuccess>(params).then(() => {
          drawer.value = false;
          loading.close();
          return tipMessage(200, "成功");
        });
      } else {
        await updateData(params);
      }
    };
    // 抽屉-保存数据
    const onHandle = async (type: "save" | "cancel", currentRt: any, who: string) => {
      if (type === "cancel") {
        return (drawer.value = false);
      }
      if (who === "title") {
        const obj: WangText = {
          ids: [currentRt.id],
          field: "name",
          name: currentRt.title
        };
        if (!currentRt.title) return tipMessage(400, "标题不能为空");

        if (currentRt.title.length < 1 || currentRt.title.length > 100) return tipMessage(400, "请输入1-100个字数内的标题");
        taskForm.value.name = currentRt.title;
        editOrAddTask(obj, "edit");
        return;
      }

      // id存在代表编辑，否则是新增任务
      if (currentRt.id) {
        const obj: WangText = {
          ids: [currentRt.id]
        };
        if (currentRt.who === "rich") {
          obj.content = currentRt.rich;
          obj.field = "content";
          taskForm.value.content = currentRt.rich;
        } else {
          obj.remark = currentRt.rich2;
          obj.field = "remark";
          taskForm.value.remark = currentRt.rich2;
        }

        await editOrAddTask(obj, "edit");
        setTimeout(() => {
          getDetailTaskLog(currentRt.id);
        }, 1000);
      } else {
        if (!currentRt.title) return tipMessage(400, "标题不能为空");

        if (currentRt.title.length < 1 || currentRt.title.length > 100) return tipMessage(400, "请输入1-100个字数内的标题");

        taskForm.value.name = currentRt.title;
        taskForm.value.content = currentRt.rich;
        taskForm.value.remark = currentRt.rich2;
        if (taskForm.value.hours || taskForm.value.hours === 0) {
          const r = /^\+?[0-9][0-9]*$/;
          if (Number(taskForm.value.hours) <= 0) return tipMessage(400, "预计工时请输入大于0的正整数");
          if (!r.test(taskForm.value.hours)) return tipMessage(400, "预计工时请输入大于0的正整数");
        }

        if (!taskForm.value.start_time || (Array.isArray(taskForm.value.start_time) && !taskForm.value.start_time.length)) {
          return tipMessage(400, "请填写计划开始结束时间");
        }
        taskForm.value.hours = taskForm.value.hours * 1;
        // 这俩重新序列化一次原因是taskForm.value.demand_status和taskForm.value.level后端要number类型，而element中select值是字符串，如果进行序列化在保存时会状态和优先级内容变成数字（闪一下）
        const obj = JSON.parse(JSON.stringify(taskForm.value));
        const times = obj.start_time ?? [];
        obj.begin_time = times[0];
        obj.end_time = times[1];
        obj.status = taskForm.value.status * 1;
        obj.level = taskForm.value.level * 1;
        obj.type = taskForm.value.type * 1;
        obj.iteration_id = taskForm.value.iteration_id ?? 0;
        obj.start_time = undefined;
        if (!obj.staff_no) return tipMessage(400, "指派给不能为空");
        editOrAddTask([obj], "add");
      }
    };
    //基础操作列表数据
    const basicsOption: BasicsOption[] = reactive([
      {
        value: "level",
        label: "优先级",
        children: PRIORITY.map((o) => {
          return { value: o.id, label: o.value };
        })
      },
      {
        value: "status",
        label: "状态",
        children: TASK_TYPE_LIST
      },
      {
        value: "staff_no",
        label: "指派给",
        children: []
      },
      {
        value: "type",
        label: "类型",
        children: TYPE_STATUS
      },
      {
        value: "demand_id",
        label: "需求",
        children: newBasicDemandLists
      },
      {
        value: "iteration_id",
        label: "迭代",
        children: iterateBasicData
      },
      {
        value: "product_id",
        label: "项目",
        children: productLists
      }
    ]);
    // 搜索列表数据
    const searchArray: SearchArray = reactive({
      btnArray: [
        { id: "-1", label: "所有", key: "" },
        { id: "0,1", label: "未完成", key: "status" },
        { id: "1", label: "进行中", key: "status" },
        { id: "2", label: "已完成", key: "status" },
        { id: "5", label: "我创建的", key: "create_by" },
        { id: "6", label: "指给我的", key: "staff_no" }
      ],
      searchForm: [
        {
          type: "input",
          label: "任务名称",
          key: "name",
          val: ""
        },
        {
          type: "select",
          label: "类型",
          val: [],
          multiple: true,
          key: "type",
          listData: TYPE_STATUS
        },
        {
          type: "select",
          label: "优先级",
          multiple: true,
          key: "level",
          val: [],
          valueKey: ["id", "value"],
          listData: PRIORITY
        },
        {
          type: "select",
          label: "状态",
          multiple: true,
          key: "status",
          val: [],
          listData: TASK_TYPE
        },
        {
          type: "select",
          label: "创建人",
          val: [],
          key: "create_by",
          showRecord: true,
          multiple: true,
          valueKey: ["staff_no", "staff_name"],
          listData: creatorTaskData
        },
        {
          type: "select",
          label: "指派给",
          val: [],
          key: "staff_no",
          showRecord: true,
          multiple: true,
          valueKey: ["staff_no", "staff_name"],
          listData: userListAll
        },
        {
          type: "select",
          label: "关联需求",
          multiple: false,
          val: [],
          key: "demand_id",
          valueKey: ["id", "name"],
          listData: newDemandLists
        },
        {
          type: "select",
          label: "关联迭代",
          multiple: false,
          val: [],
          key: "iteration_id",
          valueKey: ["id", "name"],
          listData: iterateData
        },
        {
          type: "daterange",
          label: "预计开始",
          val: [],
          typeTime: 1,
          key: "expected_start_time",
          valueKey: []
        }
      ]
    });
    // 接收方
    window.addEventListener("storage", (val) => {
      if (val.key === "setTask") {
        getData(pageTableRef.value?.getCurrentPage());
        clearSession("setTask");
      }
    });

    watch(
      () => route.query,
      () => {
        const { productId, where, detailId, staffNo, iteration_id, type, form } = route.query as Record<string, string>;

        // 迭代主页下成员维护表格跳转
        if (staffNo) {
          // 重写将默认值赋给searchParams
          formParams.value = JSON.parse(JSON.stringify(defaultSearchParams));
          formParams.value.product_id = curProductInfo.value?.id;

          formParams.value.iteration_id = iterateId.value;

          formParams.value.staff_no = [staffNo];

          searchArray.searchForm[5].val = [staffNo];
          searchArray.searchForm[7].val = iteration_id ? Number(iteration_id) : [];
          currentActive.value = "-10";

          getData(pageTableRef.value?.getCurrentPage());

          setTimeout(() => {
            // 去掉路由的参数
            router.replace({
              path: route.path,
              query: { productId, staffNo: undefined, staffName: undefined }
            });
          }, 700);
        }
        // type存在不是在header上快捷选项进行新增任务
        else if (where === "headerExploitation" && type === "add") {
          setTimeout(() => {
            addTaskDate();
          }, 700);
          // 去掉路由的参数

          router.replace({
            path: route.path,
            query: {
              ...route.query,
              type: undefined
            }
          });
        } else if (where === "workbench" && form === "workbench") {
          // 重写将默认值赋给searchParams
          formParams.value = JSON.parse(JSON.stringify(defaultSearchParams));

          handleDetail({ id: detailId });

          setTimeout(() => {
            // 去掉路由的参数
            router.replace({
              path: route.path,
              query: { ...route.query, iteration_id: undefined, id: undefined, name: undefined, where: undefined }
            });
          }, 700);
        }
      },
      {
        immediate: true
      }
    );

    // 导入回调触发事件
    const importExcelEmit = async (type: "import" | "export", cb: (s: boolean, url?: string) => void, file?: HTMLFormElement) => {
      if (type === "import" && file) {
        file.append("product_id", productId.value);
        if (route.query.iterateId) {
          file.append("iteration_id", route.query.iterateId as string);
        }
        const data = await useTaskImportExcel(file);
        typeof cb === "function" && cb(data ? data.success : false, data ? data.url : undefined);
        if (data?.success) {
          // 导入成功后，更新列表数据
          handleConditionSearch();
          ElMessage.success("导入成功");
        }
      } else {
        setObjStringify({ product_id: productId.value, type: [] }, api.taskDownloadTemplate);
      }
    };

    return {
      showImportExcel,
      importExcelEmit,
      getUserList,
      loading,
      sortChange,
      staffNo: store.getters.user.staff_no,
      getTextColor,
      handleChangeStatus,
      handleUpdateList,
      TYPE_STATUS,
      TYPE_STATUS_COPY,
      task_select,
      userList,
      pageTableRef,
      stopAutoGetData,
      handleConditionSearch,
      ...toRefs(tableData),
      getStatus,
      getData,
      handleUpdateStatus,
      dialogFormData,
      isOpenEditProgress,
      handleEditStatus,
      formParams,
      TASK_TYPE,
      TASK_TYPE_LIST,
      page,

      PRIORITY,
      handleTypeList,
      handleSelectionChange,
      selectMore,
      handleCommand,
      timeCheck,
      exportTaskDate,
      handleAddDate,

      searchArray,
      quickSeacrh,
      changeSearch,
      basicsOption,
      handleBasicChange,
      basicBtn,
      basicUserList,
      onChangeType,
      handleDelete,
      cascaderPanel,
      handleDetail,
      onChangeVal,

      richStatus,
      rich2Status,
      drawer,
      taskForm,
      headerTitle,
      onHandle,
      iterateData,
      logData,
      addTaskDate,
      handleRelativeTask,
      relativeTaskList,
      dialogRelative,
      defaultCheck,
      getRelativeDate,
      isFullScreen,
      showAddTaskBtn,
      onChangeTask,
      currentActive,
      taskFormParams,
      updateVal,
      dialogDemand,
      handleCancle,
      onChangeSelect,
      iterateId,
      hasData
    };
  }
});
