import { taskImportExcelApi } from "@/api/request-modules/product";
import { awaitFunc } from "@/utils";

export default function useExploitation() {
  // 任务列表批量导入
  const useTaskImportExcel = async (file: HTMLFormElement): Promise<{ success: boolean; url: string } | null> => {
    const [err, data]: [unknown, { success: boolean; url: string } | null] = await awaitFunc<HTMLFormElement, { success: boolean; url: string }>({
      asyncFunc: taskImportExcelApi,
      args: file
    });
    if (err || !data) return null;
    return data;
  };

  return {
    useTaskImportExcel
  };
}
